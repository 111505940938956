:root {  
    /*Margins & Paddings*/
    --padding: 1em;
    --margin: 1em;
    --vertical-padding: 1em; /* must NOT be a percentage value */
    --vertical-margin: 1em; /* must NOT be a percentage value */
     /*Breakpoints*/
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 800px;    
     /*General*/
    --container-max-width: 1100px;
    --fixed-header-height: 130px; 
    /* Slick */
    --slick-slider-height: 85vh;
    /* change settings for headings */
    --text-color: #222;
    --body-bg: #fff;
    --main-color: #fff;
    --accent-color: #81cac1;
    --effect-color: #01a894;
    --header-text-color: #fff;
    --header-bg-color: var(--main-color);
    --menu-bg-color: inherit;
    --menu-bg-hover: var(--accent-color);
    --menu-li-a-padding: 10px 15px;
    --link-color: inherit;
    --link-hover-color: inherit;
    --map-marker-color: 000;    
    /* Typo */
    --scale-factor: 1.618;
    --base-font-size: 17px;
    --base-line-height: 1.5;
    --scale-factor-m: var(--scale-factor);
    --scale-factor-s: 1.418;
    --scale-factor-xs: 1.218;   
    /* Fonts */
    --base-font-family: 'Josefin Sans', sans-serif;
    --alternative-font-family: 'Josefin Sans', Helvetica, Arial, sans-serif;
}

@import "/css/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/css/basic_styling.css";
@import "/css/flex_grid.v1.1.css";
@import "/css/slick.css";
@import "/css/slick-theme.css";
@import "/css/menu.v1.2.css";
@import "/css/magnific-popup.css";
@import "/fonts/Khula/all.css"; /* 300, 400, 400, 700, 800 */
@import "/fonts/Josefin Sans/all.css"; /* 100, 300, 300i, 400, 600, 700, 700i */
@import "/fonts/Quicksand/all.css"; 
/*@import "/cssmodules/animate.css";*/

/*--------------------------------------------
General
---------------------------------------------*/

img {
    max-width: 100%;
    height: auto;
}

.flex > * > img {
    flex: 0 0 auto;
}
/* cross-browser fix, obs! gäller endast för bilder i div utan klass*/
.flex > * > img:not([class]) {
    width: 100%;
}

.container {
    max-width: var(--container-max-width);
    padding: 0.5em;
    width: 100%;
}

html {
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
    height: 100%;
}

body {
    /*background: url('/theme/intendit/graphics/bg.png'); */
    font-family: var(--base-font-family);
    font-weight: 400;
    color: var(--text-color);
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
    background: none;
}

h1, h2, h3, h4 {
    font-family: var(--alternative-font-family);
}

h1 {
    font-size: calc(1 * var(--scale-factor) * var(--scale-factor)rem);
}

h1.twelve {
    max-width: 100%;
}

.container h1 {
    margin: 2rem 0.8rem;
}

h2 {
    font-size: calc(1 * var(--scale-factor)rem);
}

h3 {
    font-size: 1.2rem;
    color: var(--effect-color);
    padding-top: 2rem;
    margin: 0;
}

a {
    color: var(--link-color);
    transition: 0.5s ease;
}

a:hover {
    color: var(--link-hover-color);
}

.markerLabels {
    overflow: visible !important;
}

.fa-map-marker {
    background: url(/extensions/local/intendit/intenditExt/web/black/map-marker.svg);
    background-size: cover;
    padding: 10px;
    background-position: center;
}

/*--------------------------------------------------------
Header
---------------------------------------------------------*/
header {
    position: fixed;
    z-index: 22;
    background: none;
    height: 150px;
    width: 100%;
}

header .container {
    padding: 0 0.5em;
}

header a {
    text-decoration: none;
}

.homepage .logo {
    display: none;
}

.logo img {
    margin-top: -70px;
    margin-left: 1rem;
    max-width: 70px;
    height: auto;
    padding-bottom: 1rem;
}

.logo2 img {
    max-width: 250px;
    height: auto;
}

.logo a {
    font-size: 0;
}

.homepage nav {
    max-height: 60px !important;
}

header nav {
    background: var(--main-color);
    width: 100%;
    height: 80px;
}

.menu li {
    margin: 0;
}

.menu li a {
    padding: var(--menu-li-a-padding);
    color: #777;
    transition: 0.5s ease-in;
    text-transform: uppercase;
    font-size: .9rem;
    letter-spacing: 0.10rem;
    font-family: var(--alternative-font-family);
}

.menu li a:hover, .menu .active a {
    color: #222;
}

/**** Dropdown ****/

.menu .dropdown ul {
    z-index: 100;
    border-bottom: none;
}

.menu li:hover ul {
    margin-left: 0;
}

.menu .sub-menu-items li a {
    margin: 0;
    background: var(--main-color);
    color: #222;
}

.menu.menu .sub-menu-items li a:hover {
    background: var(--accent-color);
    color: #fff;
}

/**** Leftmenu ****/

.submenu ul {
    padding: 0;
}

.submenu ul li {
    list-style-type: none;
    padding: var(--padding);
    border-bottom: 1px solid #eee;
    background: #fff;
}

.submenu ul li a {
    text-decoration: none;
    color: var(--effect-color);
}

.submenu li a:hover, .submenu .active a {
    color: var(--accent-color);
}

.submenu .menu ul li {
    border-bottom: 1px solid red;
}

/*--------------------------------------------------------
Slider startsida
---------------------------------------------------------*/

.slick-slider {
    margin-bottom: 0;
}

.topslider .slick-slide {
    height: var(--slick-slider-height);
}

.topslider .slick-slide {
    background-size: cover;
    background-position: center center;
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.topslider .slick-title {
    text-align: center;
    color: white;
    /*text-shadow: 1px 1px 2px rgba(0,0,0,0.5);*/;
}

/*Slick arrows*/
.slick-prev, .slick-next {width:100px; height:100px; border:none;position:absolute; z-index:1; }  
.slick-prev {left:0px;} .slick-next {right:0px;}
.slick-prev:before, .slick-next:before { font-size:100px; color:#fff; opacity:0.9; font-family: Quicksand, sans-serif; }
.slick-prev:before {content:"<";} .slick-next:before {content:">";}

/* Content-startpage | Blocks
---------------------------------------------------------*/
.intro-content {
    position: absolute;
    top: 0;
    transform: translateY(20vh);
    right: 0;
    left: 0;
    text-align: center;
    color: #fff;
    text-shadow: 1px 1px 0 #222;
}

.intro-content p {
    font-size: 1.2rem;
}

.intro-content a {
    background: var(--effect-color);
    color: #fff;
    border: none;
    font-size: 1.6rem;
    font-family: var(--alternative-font-family);
    border-radius: 5px;
}

.intro-content a:hover {
    background: #fff;
}

.newsblock-heading {
    background: var(--effect-color);
}

.newsblock-heading h2 {
    color: #fff;
    text-align: center;
    padding: 2rem;
    margin: 0;
}

.newsblock h2 a {
    text-decoration: none;
}

.newsitem {
    background: #fff;
    padding: 2rem;
    border-bottom: 5px solid var(--effect-color); 
    outline: 5px solid var(--accent-color);
}

.publishdate {
    font-size: .9rem;
}

/*Instagram album*/
.album-bild {
    position:relative;
    margin: 5px;
}
.album-bild img {
    display:block;
 }
.info { 
    opacity:0;  
    position:absolute; 
    width:100%; 
    height:100%; 
    top:0; 
    left:0; 
    right:0; 
    bottom:0; 
    z-index:1; 
    padding: 10px; 
    font-size:0.9em; 
    line-height:1.4em; 
    text-align:right; 
    color:#777;  
    background: linear-gradient(to bottom, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0.9) 100%); 
    transition: .5s ease;
}
.info img { 
    display: inline-block;
    height:17px; 
    width:auto; 
    margin:0 0 0 5px; 
    vertical-align:top !important; 
    fill:#777 !important;
}
.album-bild:hover .info {
    opacity:1; 
}

/* Content - all
---------------------------------------------------------*/

.imagetop {
    height: 30vh;
    background: no-repeat center center;
    background-size: cover;
    margin-top: 50px;
}

/*
.maincontent ul  {
    list-style-type:none; 
    margin:0;padding:0;
}

.maincontent ul li  {
    margin:0 2% 2% 0;
    padding:  2rem;
    width: 48%; 
    float:left; 
    min-height: 300px; 
    background: #f5f5f5; 
}

.maincontent ul li strong {
    font-size: 1.3em; 
    font-weight: 700; 
} */

.table tr, td {
    border: none;
    padding: .5rem 0;
    border-bottom: 1px solid #f5f5f5;
}

.produkt-item {
    box-shadow:0 0 0 1px #ccc;
}


.prod-title {
    text-decoration: none;
    text-align: center;
}

.produkt-item a {
    text-decoration: none;
}

.Kontakt .imagetop {
    display: none;
}


.maincontent {
    margin-top: 20px;
}

.Kontakt .maincontent {
    margin-top: 60px;
}



/* Boxar / Zoomboxar
-------------------------------------------------*/

.boxes {
    background:linear-gradient(0deg,rgba(255,255,255,.7),rgba(255,255,255,.7)), url('/theme/intendit/graphics/boxbg.jpg')
}


.boxes a {
    text-decoration: none;
    color: #fff;
}

.boxes a:hover {
    color: #222;
}

.boxbutton, button, input[type=reset], input[type=submit] {
    border: none;
    border-radius: 2px;
    padding: 0.5rem;
    background: #fff;
    color: var(--accent-color);
}

.boxwrapper {
    height: 40vh;
    background-size: cover;
    background-position: center;
}

.boxwrapper:nth-child(odd) {
    background: rgba(1,168,148,.3);
}


.zoombox .boxwrapper {
    background-size: 0% 0%;
    position: relative;
    overflow: hidden;
    text-decoration: none;
}

.boxwrapper h2 {
    color: var(--effect-color);
    margin: 0;
    text-align: center;
    z-index: 3;
    font-weight: 400;
    margin-bottom: 1rem;
    font-size: 2.2rem; /* 2.8 */
}

.zoombox .boxwrapper[style]:before, .boxwrapper:after, .boxwrapper figure img, .boxwrapper figure figcaption {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.zoombox .boxwrapper[style]:before {
    content: '';
    background-image: inherit;
    background-size: cover;
    background-position: center center;
    transition: transform .65s ease-in 0s;
}

.zoombox .boxwrapper figure img {
    transition: transform .65s ease-in 0s;
}

.zoombox .boxwrapper:after {
    content: '';
    background: rgba(0,0,0,0);
    transition: background 1.65s ease-in 0s;
    z-index: 2;
}

.zoombox .boxwrapper:hover:after {
    background: rgba(5,135,150,0.6);
    transition: background 1.65s ease-out 0s;
}

.zoombox .boxwrapper[style]:hover:before, .boxwrapper:hover figure img {
    transform: scale(1.1);
    transition: transform .65s ease-out 0s;
}

.zoombox .box-teaser {
    z-index: 101;
    opacity: 0;
    text-align: center;
    transition: 1.1s ease;
    color: white;
}

.zoombox .boxwrapper:hover .box-teaser {
    opacity: 1;
}


/* Contact
---------------------------------------------------------*/


.kontakt svg {
    margin-right: 0.5rem;
}


/* listing items
---------------------------------------------------------*/

.listing-item {
    margin-right: 0;
    padding-right: 0;
    background: var(--main-color);
    margin-bottom: 10px;
}


.pagination ul {
    float: left;
    clear: both;
    display: block;
    margin: 8px 0;
    padding: 0;
    border: 1px solid #DDD;
}

.pagination ul li {
    float: left;
    list-style-type: none;
    border-right: 1px solid #DDD;
    padding: 4px 6px;
}

.pagination ul li:last-child {
    border-right: none;
}

.pagination ul li.active {
    font-weight: bold;
    background-color: var(--link-color);
}

.pagination ul li.active a {
    color: white;
}

.pagination ul li a {
    text-decoration: none;
}

/* Footer
---------------------------------------------------------*/

footer {
    background: var(--accent-color);
    color: #fff;
}

footer a {
    text-decoration: none;
}

footer h3 {
    font-size: 1.6rem;
    color: #fff;
    padding-bottom: 1rem;
}

footer svg.fa-icon {
    fill: currentColor;
    height: calc(1 * var(--scale-factor)rem);
    transition: ease-in 0.5s;
}

footer svg.fa-icon:hover {
    fill: var(--accent-color);
}

/*Google maps*/
.map-canvas {
    height: 35vh;
}

.map-canvas img {
    max-width: none;
}
 
/* Intendit footer
-------------------------------------------------*/
.created-by {
    background: var(--main-color);
    padding: 0.5em 0;
    text-align: center;
}

.created-by .container, .intenditfooter {
    padding: 0;
    margin: 0 auto;
}

.created-by .container .intenditfooter, .created-by .container .intenditfooter a {
    color: #ccc;
}


/*------------------------------------------
Sticky footer
-------------------------------------------*/
.site {
    display: flex;
    flex-direction: column;
    height: 100%;
}

main {
    flex: 1 0 auto;
    min-height: 1%;
 /* IE fix */;
}

header, footer {
    flex: none;
}

footer .contactinfo p {
    margin: 0;
}


/* general
---------------------------------------------------------*/

.contactform .boltform textarea {
    min-height: 120px;
}

.contactform .boltforms-row {
    margin: .2rem;
}

input[type=email], input[type=number], input[type=search], input[type=text], input[type=tel], input[type=url], input[type=password], textarea {
    width: 100%;
    color: #222;
    padding: 1rem;
}

button[type=submit], input[type=submit] {
    margin: 0;
    background: var(--accent-color);
    float: right;
    border: none;
    transition: ease-in 0.5s;
}

button[type=submit]:hover, input[type=submit]:hover {
    background: var(--effect-color);
    color: #fff;
    border: none;
}

.fa-map-marker {
    background: url('data:image/svg+xml;utf8,%3Csvg%20fill%3D%22%23var(--map-marker-color)%22%20width%3D%221792%22%20height%3D%221792%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M1152%20640q0-106-75-181t-181-75-181%2075-75%20181%2075%20181%20181%2075%20181-75%2075-181zm256%200q0%20109-33%20179l-364%20774q-16%2033-47.5%2052t-67.5%2019-67.5-19-46.5-52l-365-774q-33-70-33-179%200-212%20150-362t362-150%20362%20150%20150%20362z%22%2F%3E%3C%2Fsvg%3E');
    background-size: cover;
    padding: 10px;
    background-position: center;
}

/*Not found*/
.pagenotfound {
    min-height: 100vh;
    position: relative;
    text-align: center;
    font-size: 1.1em;
}

.pagenotfound .logo img {
    max-height: 70px;
    width: auto;
    max-width: 100%;
    display: block;
}

.pagenotfound ul {
    list-style-type: none;
    padding: 0;
    margin: 0.5em auto;
    width: 100%;
    max-width: 550px;
}

.pagenotfound li a {
    border-bottom: 1px dashed #d0d0d0;
    display: inline-block;
    padding: 10px 110px;
}

/* Media Queries
---------------------------------------------------------*/

@media (max-width: var(--breakpoint-m)) {
      
    h1 {
        font-size: calc(1 * var(--scale-factor-m) * var(--scale-factor-m)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-m)rem);
    }
}

@media (max-width: var(--breakpoint-s)) {
    
    header {
        height: auto;
    }
    
    h1 {
        font-size: calc(1 * var(--scale-factor-s) * var(--scale-factor-s)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-s)rem);
    }

    .intenditfooter {
        text-align: center;
    }
    
}


@media (max-width: var(--breakpoint-xs)) {
    
    footer {
        text-align: center;
    }
    
}

@media (max-width: var(--breakpoint-menu)) {
   
    .menu .dropdown ul {
        border: none !important;
        background: var(--accent-color) !important;
        width: 100%;
        padding-left: 30px;
    }

    .menu .dropdown ul li a {
        font-size: .8rem;
    }

    .menu li a, .menu li li a {
        padding: 8px !important;
    }
    
    .menu li, .menu li ul, .menu li:hover ul {
        margin: 0 !Important;
    }

    .menu.menu li {
        margin: 0;
    }

    .menu.menu li a {
        margin: 0;
        background: var(--accent-color);
        color: #fff;
        font-size: .8rem;
    }

    .menu .active a {
        color: #fff;
        background: var(--effect-color) !important;
    }
    
    #menu-icon:before, #menu-icon:after, #menu-icon span {
        border: 1px solid;
    }
}



/********** FB *************/
@media (min-width: var(--breakpoint-s)) {
    .news-block {
        top: 20em;
        right:12em;
        bottom: 5em;
        width: 50%;
        padding: 1em 2em;
        width: 30%;
        overflow: hidden;
    }
    
    mark {
    background: none;
        color: #fff}

    .news-block-inner {
        overflow-y:scroll;
        height:350px;
    }
}

.news-post p  {
    margin-top:0;
}

.button {
    margin: 0;
    background: var(--effect-color);
    color: white;
    border-radius: 0;
    border: none;
}

.button:hover {
    background: #333;
}
